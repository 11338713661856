import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Config {
    apiBaseUrl: string
    apiKey: string
    imgBbApiKey: string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public config!: Config;

  constructor(private http: HttpClient) {}

  loadConfig(): Observable<any> {
    return this.http.get('/assets/app.config.json');
  }

  setConfig(config: any) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }
}