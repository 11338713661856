import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VisualService } from "@shared/services/visual.service";
import { Observable, catchError, tap } from "rxjs";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(
        private visual: VisualService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this.visual.loading) {
            this.visual.loading = true;
        }
        return next.handle(request).pipe(
            tap({
                next: (event: HttpEvent<any>) => {
                    if ((event as any)?.status) {
                        if (this.visual.loading) {
                            this.visual.loading = false;
                        }
                    }
                },
                error: err => {
                    if (this.visual.loading) {
                        this.visual.loading = false;
                    }
                }
            })
        );
    }
}