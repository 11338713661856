<ion-header>
    <ion-toolbar>
      <ion-title>Login</ion-title>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <ion-card class="desktop-container">
      <ion-card-header>
        <ion-card-title>Login</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-item>
          <ion-label position="floating">Login</ion-label>
          <ion-input type="text" [(ngModel)]="loginData.username"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Password</ion-label>
          <ion-input type="password" [(ngModel)]="loginData.password"></ion-input>
        </ion-item>
        <ion-button expand="full" (click)="login()">Login</ion-button>
      </ion-card-content>
    </ion-card>
  </ion-content>