import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { STORAGE } from "@core/misc/consts";

const TAGS_KEY = 'tags';
const TAG_ADMIN = 'admin';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    jwtToken?: string;
    decodedToken?: { [key: string]: string };

    username?: string;
    tags?: string;

    constructor(
        private router: Router,
    ) {
        const oldToken = localStorage.getItem(STORAGE.TOKEN);
        this.setToken(oldToken || '');
    }

    setToken(token: string) {
        if (token) {
            this.jwtToken = token;
            localStorage.setItem(STORAGE.TOKEN, token);
            this.decodedToken = JSON.parse(atob(this.jwtToken.split('.')[1]));
            if (this.decodedToken) {
                this.username = this.decodedToken['username'];
                this.tags = this.decodedToken[TAGS_KEY];
            }

        }
    }

    logout() {
        this.jwtToken = undefined;
        this.decodedToken = undefined;
        this.username = undefined;
        localStorage.removeItem(STORAGE.TOKEN);
        this.router.navigateByUrl('login', { replaceUrl: true });

    }

    getExpiryTime() {
        return this.decodedToken ? this.decodedToken['exp'] : null;
    }

    isTokenExpired(): boolean {
        if (!this.getExpiryTime()) {
            return true;
        }

        const expiryTime: number = Number(this.getExpiryTime());
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
        } else {
            return false;
        }
    }

    getTags() {
        return this.tags!;
    }

    hasTags(requiredTags: string[]) {
        return requiredTags.every(tag => this.tags!.includes(tag));
    }

    isAdmin() {
        return this.tags?.includes(TAG_ADMIN);
    }
}