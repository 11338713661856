import { HttpErrorResponse, type HttpEvent, type HttpHandler, type HttpInterceptor, type HttpInterceptorFn, type HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.serivce';
import { ToastController } from '@ionic/angular';
import { Observable, tap } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private toastController: ToastController
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // TO DO CHANGE IT
    if (req.url === 'https://api.imgbb.com/1/upload') {
      return next.handle(req);
    }

    const authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + this.auth.jwtToken } });

    //return next.handle(authReq);

    return next.handle(authReq).pipe(tap(() => { },
      async (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          const message = 'Unauthorized, please log in';
          const toast = await this.toastController.create({
            position: 'top',
            duration: 5000,
            message: message,
            color: 'danger'
          })
          await toast.present();
          this.router.navigate(['login']);
        }
      }));
  }
}