import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { environment } from 'environments/environment';
import { ConfigService } from './config.service';

export type InviteCity = 'Al Quoz - Dubai (UAE)' | 'Kobyłka - Warszawa (Poland)';
export type InviteStatus = 'new' | 'in_progress' | 'done' | 'canceled';

export interface InviteDto {
  id?: string;
  name: string;
  email: string;
  dialCode: string;
  phoneNumber: string;
  city: InviteCity;
  createDate?: Date;
  meetingDate?: Date;
  notes?: string;
  status?: InviteStatus;
}

interface LoginResponse {
  access_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly apiUrl = this.config.config.apiBaseUrl;
  private readonly apiKey = this.config.config.apiKey;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private toastController: ToastController
  ) { }

  login(username: string, password: string) {
    const header = new HttpHeaders({ 'x-api-key': this.apiKey });
    return this.http.post<LoginResponse>(this.apiUrl + 'auth/login', { username, password }, { headers: header });
  }

  getInvities() {
    const header = new HttpHeaders({ 'x-api-key': this.apiKey });
    return this.http.get<InviteDto[]>(this.apiUrl + 'test-drives/', { headers: header });
  }

  postInvite(dto: InviteDto) {
    const header = new HttpHeaders({ 'x-api-key': this.apiKey });
    return this.http.post(this.apiUrl + 'test-drives/', dto, { headers: header })
      .pipe(
        catchError(async (e: HttpErrorResponse) => {
          const message = e.error?.message ? this.joinStrings(e.error?.message) : JSON.stringify(e);
          const toast = await this.toastController.create({
            position: 'top',
            duration: 5000,
            message: message,
            color: 'danger'
          })
          await toast.present();
          throw e;
        })
      );
  }

  editInvite(dto: InviteDto) {
    const header = new HttpHeaders({ 'x-api-key': this.apiKey });
    return this.http.patch(this.apiUrl + 'test-drives/' + dto.id, dto, { headers: header })
      .pipe(
        catchError(async (e: HttpErrorResponse) => {
          const message = e.error?.message ? this.joinStrings(e.error?.message) : JSON.stringify(e);
          const toast = await this.toastController.create({
            position: 'top',
            duration: 5000,
            message: message,
            color: 'danger'
          })
          await toast.present();
          throw e;
        })
      );
  }

  joinStrings(input: string | string[]): string {
    if (typeof input === 'string') {
      // Jeśli argumentem jest pojedynczy string, zwróć go bez zmiany
      return input;
    } else if (Array.isArray(input)) {
      // Jeśli argumentem jest tablica stringów, użyj metody join() do połączenia ich
      return input.join(', ');
    } else {
      // Obsługa innych przypadków, np. null, undefined
      return '';
    }
  }

}
