<!-- page-not-found.page.html -->
<ion-header>
    <ion-toolbar>
      <ion-title>
        Page Not Found
      </ion-title>
    </ion-toolbar>
  </ion-header>
  
  <ion-content class="ion-padding">
    <div class="ion-text-center">
      <img class="img" src="assets/graphics/fun_404.jpg" alt="404image">
      <h2>404</h2>
      <p>Oops! The page you're looking for does not exist.</p>
      <ion-button [routerLink]="'/home'" color="primary">Go to Home</ion-button>
    </div>
  </ion-content>