import { Component } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  showSpinner: boolean = false;

  constructor(private router: Router) {
      this.router.events.subscribe(
              event => {
                if(event instanceof RouteConfigLoadStart) {
                  this.showSpinner = true;
                  return;
                }
                if(event instanceof RouteConfigLoadEnd) {
                  this.showSpinner = false;
                  return;
                }
          }
      );
   }
}
