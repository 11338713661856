import { ChangeDetectorRef, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VisualService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  public get loading(): boolean {
    return this.loadingSubject.getValue();
  }

  set loading(value: boolean) {
    this.loadingSubject.next(value);
  }
}