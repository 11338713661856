import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.serivce';

export const tagsGuard: CanActivateFn = (route, state) => {

    const token = inject(AuthService);
    const router = inject(Router);

    const requiredTags = route.data['tags'] as string[];
    const userTags = token.getTags();

    if (!userTags) {
        router.navigate(['/login']);
        return false;
    }

    const hasTag = token.hasTags(requiredTags);
    if (!hasTag) {
        router.navigate(['/unauthorized']); // Strona lub komponent "Unauthorized"
        return false;
    }

    return true;

};
