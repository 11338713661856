import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NormalButtonComponent } from './shared/normal-button/normal-button.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '@core/misc/auth.interceptor';
import { LoadingInterceptor } from '@shared/misc/loading.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigService } from '@core/services/config.service';
import { Observable, tap } from 'rxjs';

export function initializeApp(configService: ConfigService): () => Observable<any> {
  return () => configService.loadConfig().pipe(
    tap(config => configService.setConfig(config))
  );
}


@NgModule({
  declarations: [
    AppComponent, 
    NormalButtonComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ] ,
  bootstrap: [AppComponent],
})
export class AppModule {}
